import { useEffect, useRef } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { getUserId } from '@/redux/auth/selectors'
import { fetchUserProfile } from '@/redux/profile/actions'
import { getUserDisplayName } from '@/redux/profile/selectors'

export default function useUserProfile() {
  const dispatch = useDispatch()
  const userId = useSelector(getUserId)
  const userName = useSelector(getUserDisplayName)
  const shouldFetchProfile = useRef(true)

  useEffect(() => {
    if (shouldFetchProfile.current && userId && !userName) {
      dispatch(fetchUserProfile(userId))
      shouldFetchProfile.current = false
    }
  }, [dispatch, userId, userName])
}
