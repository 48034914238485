import isEmptyObject from 'is-empty-object'
import { createSelector } from 'reselect'

import { getSiteBaseUrl } from '@helpers/constants'
import { sharePaths } from 'globals/marketing'

/*
  statuses from state.profile.status.verificationStatus

  This status determines a users overall order permissions
  3 = can purchase adultUse AND medical
  6 = can purchase medical only, the user is under 21
*/
export const USER_STATUSES = {
  NONE: 0, // This enum value does not exist in backend, but is used by banner system as init value.
  INCOMPLETE: 1,
  PENDING: 2,
  VERIFIED: 3,
  EXPIRED: 4,
  DECLINED: 5,
  ADULT_USE: 6
}

/*
  statuses from state.profile.basic.verificationStatus
*/
export const BASIC_STATUSES = {
  NONE: 0, // This enum value does not exist in backend, but is used by banner system as init value.
  INCOMPLETE: 1,
  PENDING: 2,
  VERIFIED: 3,
  EXPIRED: 4,
  DECLINED: 5
}

/*
  statuses from state.profile.patient.verificationStatus
*/
export const PATIENT_STATUSES = {
  NONE: 0, // This enum value does not exist in backend, but is used by banner system as init value.
  INCOMPLETE: 1,
  PENDING: 2,
  VERIFIED: 3,
  EXPIRED: 4,
  DECLINED: 5
}

export const ELIGIBILITY_PROOF_TYPES = {
  PASSPORT: 7
}

export const PERSONA_STATUSES = {
  ACCEPT: 'accept',
  COMPLETED: 'completed',
  FAILED: 'failed',
  REJECT: 'reject'
}
type ShortInviteUrls = {twitter: string, facebook: string, sms: string, email: string, link: string}

const getState = (state) => state

export const getProfile = createSelector([getState], (state) => {
  return state.profile
})

export const getProfileError = createSelector([getProfile], (profile) => profile.error)

/*
 Get Profile status objects
*/
export const getExtra = createSelector([getProfile], (profile) => profile.extra)

export const getPatient = createSelector([getProfile], (profile) => profile.patient)

export const getBasic = createSelector([getProfile], (profile) => {
  return profile.basic
})

export const getProfileStatus = createSelector([getProfile], (profile) => profile.status)

export const getProfileUserFeatures = createSelector(
  [getProfileStatus],
  (profileStatus) => profileStatus?.featuresEnabled || []
)

export const getStat = createSelector([getProfile], (profile) => profile.stat)

/*
 Get verification status of status objects
*/
export const getPatientStatus = createSelector([getPatient], (patient) => patient.verificationStatus)

export const getBasicStatus = createSelector([getBasic], (basic) => basic.verificationStatus)
// The overall verification status of a user, this is how you check for adultUse
export const getUserVerificationStatus = createSelector(
  [getProfileStatus],
  (status) => status.verificationStatus || USER_STATUSES.NONE
)

export const getIsUserVerified = createSelector([getUserVerificationStatus], (status) => status === 6 || status === 3)


/*
  Return the short invite Urls that redirect to a UTM param enhanced URL
*/
export const getShortInviteUrls = createSelector([getExtra], (extra) => { 
  const links = {}
  Object.keys(sharePaths).forEach((k) => {
    const url = getSiteBaseUrl() + `${sharePaths[k]}`
    links[k] = !extra.inviteCode ? null : `${url}/${extra.inviteCode}`
  })
  return links as ShortInviteUrls 
})

/*
  Return if the phone number is verified
*/
export const getPhoneVerifiedStatus = createSelector([getProfileStatus], (status) => {
  return !isEmptyObject(status) && status.isMobilePhoneVerified
})

/* I don't know why we have two different places for phone numbers and they're called different things :( */
export const getPhoneNumber = createSelector([getProfileStatus], (status) => status.phoneNumber)

export const getPhoneNumberBasic = createSelector([getBasic], (basic) => basic.mobilePhone)

/*
  Return if the phone number is verified, and...something else prob banner related.
*/
export const getIsPhoneVerified = createSelector([getPhoneVerifiedStatus, getBasic], (isMobilePhoneVerified, basic) => {
  // Ask Nick why this is here. Otherwise, DO NOT USE.
  if (!basic.email) return true
  return isMobilePhoneVerified
})

/*
  Return true if either email isn't present (indicating profile is still loading)
  or if there is a phone number for the user
*/
export const getIsPhonePresent = createSelector([getBasic], (basic) =>
  basic.email ? Boolean(basic.mobilePhone) : true
)

/*
  BASIC status
*/
export const isBasicIncomplete = createSelector(
  [getBasicStatus],
  (status) => !!status && status === BASIC_STATUSES.INCOMPLETE
)

export const isBasicPending = createSelector(
  [getBasicStatus],
  (status) => !!status && status === BASIC_STATUSES.PENDING
)

export const isBasicDeclined = createSelector(
  [getBasicStatus],
  (status) => !!status && status === BASIC_STATUSES.DECLINED
)

export const isBasicExpired = createSelector(
  [getBasicStatus],
  (status) => !!status && status === BASIC_STATUSES.EXPIRED
)

export const isBasicVerified = createSelector(
  [getBasicStatus],
  (status) => !!status && status === BASIC_STATUSES.VERIFIED
)

/*
  MMID status
*/
export const isMedicalIncomplete = createSelector(
  [getPatientStatus],
  (status) => !!status && status === PATIENT_STATUSES.INCOMPLETE
)

export const isMedicalPending = createSelector(
  [getPatientStatus],
  (status) => !!status && status === PATIENT_STATUSES.PENDING
)

export const isMedicalDeclined = createSelector(
  [getPatientStatus],
  (status) => !!status && status === PATIENT_STATUSES.DECLINED
)

export const isMedicalExpired = createSelector(
  [getPatientStatus],
  (status) => !!status && status === PATIENT_STATUSES.EXPIRED
)

export const isMedicalVerified = createSelector(
  [getPatientStatus],
  (status) => !!status && status === PATIENT_STATUSES.VERIFIED
)

/*
  NOTE: a user may also be medical verified and able to purchase adultUse. You have to check for both in adult use areas.
*/
export const isAdultUseVerified = createSelector(
  [getUserVerificationStatus],
  (status) => !!status && status === USER_STATUSES.ADULT_USE
)

const getTimeUntilExpired = (expirationDate) => {
  const expiration = new Date(`${expirationDate}Z`)
  const now = new Date()

  return expiration.getTime() - now.getTime()
}

const getTimeUntilRecExpired = createSelector([getPatient], (patient) => {
  if (!isEmptyObject(patient)) {
    return getTimeUntilExpired(patient.expirationDate)
  } else {
    return false
  }
})

const ONE_DAY = 86400 * 1000

export const getDaysUntilRecExpired = createSelector([getTimeUntilRecExpired], (time) => {
  if (time) return  Math.floor(time / ONE_DAY)
})

export const getRecExpired = createSelector([getPatient, getTimeUntilRecExpired], (patient, time) => {
  if (!isEmptyObject(patient) && patient.expirationDate && time) {
    return time < 0
  } else {
    return false
  }
})

// in ms
const THIRTY_DAYS = ONE_DAY * 30

export const getRecExpiring = createSelector([getPatient, getTimeUntilRecExpired], (patient, time) => {
  if (!isEmptyObject(patient) && patient.expirationDate && time) {
    return time < THIRTY_DAYS && time > 0
  } else {
    return false
  }
})

export const DECLINE_REASONS = {
  NONE: 0,
  STATE_ID_NOT_READABLE: 1,
  STATE_ID_WRONG: 2,
  MMID_NOT_READABLE: 4,
  MMID_WRONG: 8,
  REC_LETTER_NOT_READABLE: 16,
  REC_LETTER_WRONG: 32,
  OTHER: 64,
  STATE_ID_NOT_CA: 128,
  UNDER_21: 256,
  STATE_ID_EXPIRED: 512,
  STATE_ID_INVALID: 1024,
  STATE_ID_DUPLICATED: 2048, // entity_match_detected/id_repeat_detection/id_duplicate_detected
  STATE_ID_BLOCKED: 4096,
  SELFIE_ERROR: 8192
}

export const DECLINE_REASON_TEXT = {
  NONE: '',
  STATE_ID_NOT_READABLE: 'We were unable to read your state ID.',
  STATE_ID_WRONG: 'The ID you uploaded is not an valid form of identification.',
  MMID_NOT_READABLE: 'We were unable to read your medical marijuana identification card (MMID).',
  MMID_WRONG: 'The ID you uploaded is not an valid form of identification.',
  REC_LETTER_NOT_READABLE: 'We were unable to read your recommendation.',
  REC_LETTER_WRONG: 'The ID you uploaded is not an valid form of identification.',
  STATE_ID_NOT_CA: 'Your state ID was declined.',
  UNDER_21: 'You must be 21+ to purchase cannabis on Eaze.',
  STATE_ID_EXPIRED: 'Your ID is expired.',
  STATE_ID_INVALID: 'Your ID is not valid for use on Eaze.',
  STATE_ID_DUPLICATED: 'Your ID needs further review.',
  STATE_ID_BLOCKED: 'Your ID is not valid for use on Eaze.',
  SELFIE_ERROR: 'Your selfie did not match your ID.'
}

export const getDeclinedReasonIds = createSelector([getProfileStatus], (status) => {
  if (!status || isEmptyObject(status)) return []
  return status.declineReasons || []
})

export const getDeclinedReasons = createSelector([getDeclinedReasonIds], (reasonIds) => {
  if (!reasonIds || !reasonIds.length) return []

  const declineReasons = []

  reasonIds.forEach((declineReasonId) => {
    switch (declineReasonId) {
      case DECLINE_REASONS.STATE_ID_NOT_READABLE:
        declineReasons.push(DECLINE_REASON_TEXT.STATE_ID_NOT_READABLE)
        break

      case DECLINE_REASONS.STATE_ID_WRONG:
        declineReasons.push(DECLINE_REASON_TEXT.STATE_ID_WRONG)
        break

      case DECLINE_REASONS.MMID_NOT_READABLE:
        declineReasons.push(DECLINE_REASON_TEXT.MMID_NOT_READABLE)
        break

      case DECLINE_REASONS.MMID_WRONG:
        declineReasons.push(DECLINE_REASON_TEXT.MMID_WRONG)
        break

      case DECLINE_REASONS.REC_LETTER_NOT_READABLE:
        declineReasons.push(DECLINE_REASON_TEXT.REC_LETTER_NOT_READABLE)
        break

      case DECLINE_REASONS.REC_LETTER_WRONG:
        declineReasons.push(DECLINE_REASON_TEXT.REC_LETTER_WRONG)
        break

      case DECLINE_REASONS.STATE_ID_NOT_CA:
        declineReasons.push(DECLINE_REASON_TEXT.STATE_ID_NOT_CA)
        break

      case DECLINE_REASONS.UNDER_21:
        declineReasons.push(DECLINE_REASON_TEXT.UNDER_21)
        break

      case DECLINE_REASONS.STATE_ID_EXPIRED:
        declineReasons.push(DECLINE_REASON_TEXT.STATE_ID_EXPIRED)
        break

      case DECLINE_REASONS.STATE_ID_INVALID:
        declineReasons.push(DECLINE_REASON_TEXT.STATE_ID_INVALID)
        break

      case DECLINE_REASONS.STATE_ID_DUPLICATED:
        declineReasons.push(DECLINE_REASON_TEXT.STATE_ID_DUPLICATED)
        break

      case DECLINE_REASONS.STATE_ID_BLOCKED:
        declineReasons.push(DECLINE_REASON_TEXT.STATE_ID_BLOCKED)
        break

      case DECLINE_REASONS.SELFIE_ERROR:
        declineReasons.push(DECLINE_REASON_TEXT.SELFIE_ERROR)
        break

      case DECLINE_REASONS.NONE:
        break

      default:
        break
    }
  })

  return declineReasons
})

export const getDeclinedComment = createSelector([getProfileStatus], (status) =>
  !status ? null : status.declineReasonOther
)

export const getInviteeAmount = createSelector([getExtra], (extra) => extra.inviteAmount)

export const getInviterAmount = createSelector([getExtra], (extra) => extra.inviteAmountForInviter)

export const getCreditAmount = createSelector([getExtra], (extra) => extra.totalCredit)

export const getAge = createSelector([getBasic], (basic) => basic.age)

export const getEmail = createSelector([getBasic], (basic) => basic.email)

export const getUserDisplayName = createSelector([getBasic], (basic) => basic.displayName)

export const getTotalOrdersCount = createSelector([getProfile], (profile) => profile.stat.totalOrdersCount)

export const getPasswordChangeStatus = createSelector([getProfile], (profile) => profile.changedPassword)

export const getPasswordChangeError = createSelector([getProfile], (profile) => profile.changedPasswordError)

export const getPasswordStore = createSelector([getProfile], (profile) => profile.password)

export const getPatientContentUrl = createSelector([getPatient], (patient) => patient.contentUrl)

export const getZipCode = createSelector([getBasic], (basic) => basic.zipCode)

export const getIdCardType = createSelector([getBasic], (basic) => basic.eligibilityProofType)

export const hasUsaId = createSelector([getBasic], (basic) => basic.isUSA)

export const getBasicFlags = createSelector([getBasic], (basic) => basic.flags)

export const getPersonaVerificationStatus = createSelector(
  [getProfileStatus],
  (status) => status.personaVerificationStatus
)
